import TinderCard from 'react-tinder-card'
import React , { useContext, useEffect , useRef, useState } from 'react'
import { ContextProvider } from '../context/Context'
import { Link, useHistory } from 'react-router-dom'
import API, { imageURL } from '../api/axios'
import './Main.css'
// import ReactPlayer from 'react-player'

function Main() {

      const { authenticated , setAuthenticated } = useContext(ContextProvider)

    const [media , setMedia] = useState([])

    const [products , setProducts] = useState([])

    const refVideo = useRef(null)

    let history = useHistory()

    useEffect(() => {
      const randomMedia = async () => {
        await API.randomMedia().then((res) => {
            setMedia(res.data)
        })
      }
      randomMedia()

      const randomProduct = async () => {
         await API.randomProduct().then((res) => {
             setProducts(res.data)
         })
       }
       randomProduct()
    } , [])

  const [lastDirection, setLastDirection] = useState()

  const swiped = (direction, nameToDelete) => {
    console.log('removing: ' + nameToDelete)
    setLastDirection(direction)
  }

  const outOfFrame = (name) => {
    console.log(name + ' left the screen!')
  }
console.log(products)
    return (
        <div>
        <div className="header-for-bg">
        <div className="background-header position-relative">
           <img src="/images/page-img/profile-bg5.jpg" className="img-fluid rounded w-100 rounded rounded" alt="profile-bg" />
           <div className="title-on-header">
              <div className="data-block">
                 <h2>Home Page</h2>
              </div>
           </div>
        </div>
     </div>
     <br></br>
     <div className="container">
               <div className="row">
     <div className="col-sm-12">
     <div className="iq-card">
                        <div className="iq-card-body p-0">
                           <div className="user-tabing">
                              <ul className="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link active" data-toggle="pill" href="#photos">Photos</a>
                                 </li>
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link" data-toggle="pill" href="#videos">Videos</a>
                                 </li>
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link" data-toggle="pill" href="#youtube">Youtube</a>
                                 </li>
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link" data-toggle="pill" href="#products">Marketplace</a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>





                     <div className="tab-content">
                     <div className="tab-pane fade active show" id="photos" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row" style={{ padding : 20 }}>
                                  
                                    <div className="col-md-12" style={{ display : 'flex' , justifyContent : 'center' , alignItems : 'center' , height : 500 }}>
                                       <div className="tab-content">
                                         
                                         
                                          
                                         <div className='cardContainer'>
  
           {
               media.map((value , key) => {
                   if(value.type === "photo")
                   return (
                    <TinderCard className='swipe' key={value.id} onSwipe={(dir) => swiped(dir, value.title)} onCardLeftScreen={() => outOfFrame(value.title)}>
                    <div style={{ backgroundImage: 'url(' + imageURL + '/' + value.file + ')' }} className='card' onDoubleClick={(e) => console.log(e)}>
                    <h3>{value.user.username}</h3>
                 </div>
                    </TinderCard>
                   )
               })
           }
       </div>
            
                                       </div>
                                       
                                    </div>
                                    {lastDirection ? <h2 className='infoText' style={{ color : 'black' }}>You swiped {lastDirection}</h2> : <h2 className='infoText' />}

                                 </div>
                              </div>
                           </div>
                        </div>
         
                        <div className="tab-pane fade" id="videos" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row" style={{ padding : 20 }}>
                                  
                                    <div className="col-md-12" style={{ display : 'flex' , justifyContent : 'center' , alignItems : 'center' , height : 500 }}>
                                       <div className="tab-content">
                                         
                                         
                                          
                                         <div className='cardContainerVideo'>
  
           {
               media.map((value) => {
                   if(value.type === "video")
                   return (
                    <TinderCard className='swipe' key={value.id} 
                    onSwipe={(dir) => { swiped(dir, value.title) }} 
                    onCardLeftScreen={(e) => { outOfFrame(value.title);console.log(refVideo.current.pause()) }}>
                    <video ref={refVideo} className='cardVideo'
                  //   onMouseOver={(e) => e.target.play()}
                    onClick={(e) => { e.target.paused ? e.target.play() : e.target.pause() }}
                     onDoubleClick={(e) => { e.target.muted ? e.target.muted = false : e.target.muted = true }}
                      muted={true}>
                    <source src={`${imageURL}/${value.file}`}   
            typeof="video/webm" />
                    <source src={`${imageURL}/${value.file}`}
            typeof="video/mp4" />
                    </video>
                    </TinderCard>
                   )
               })
           }
       </div>
            
                                       </div>
                                       
                                    </div>
                                    {/* {lastDirection ? <h2 className='infoText' style={{ color : 'black' }}>You swiped {lastDirection}</h2> : <h2 className='infoText' />} */}
<h3 className='infoText' style={{ color : 'black' }}>Click to play or pause and double click to mute or unmute</h3>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="tab-pane fade" id="youtube" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row">
                                  
                                    <div className="col-md-12">
                                       <div className="tab-content">
              
                                       <div id="content-page" className="content-page">
               <div className="row">
               {
               media.map((value) => {
                   if(value.type === "youtube")
                   return (
                    <div className="col-md-6 col-lg-6 mb-3" key={value.id}>
                    <div className="user-images position-relative overflow-hidden">
                       {/* <ReactPlayer url={value.file} className="img-fluid rounded" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></ReactPlayer> */}
                       <iframe className="cardContainerYoutube" src={value.file} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        {/* <div style={{ backgroundColor : '#50b5ff' , marginTop : 5 , borderRadius : 5 , paddingBottom : 10 }}>
                        <h3 style={{ padding : 10 , color : 'black' }}>{value.title}</h3>
                        <h5 style={{ paddingLeft : 20 , color : 'white' }}>{value.description}</h5>
                        </div> */}
                    </div>
                 </div>
                   )
               })
            }
                 
               </div>
         </div>                 
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>



        <div className="tab-pane fade" id="products" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row">
                                  
                                    <div className="col-md-12">
                                       <div className="tab-content">
              
                                       <div id="content-page" className="content-page">
               <div className="row">
                  
               {
                            products.map((value) => (
                                <div className="col-sm-6 col-md-6 col-lg-4" key={value.id}>
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
                              <div className="iq-card-body">
                                 <div className="d-flex align-items-center justify-content-between pb-3">
                                    <div className="d-flex align-items-center">
                                       <img className="img-fluid rounded-circle avatar-30" src={ value.user.image ? imageURL + '/' + value.user.image : '/images/user/11.png'} alt="" />
                                       <div className="media-body ml-2">
                                          <p className="mb-0 line-height">Posted By</p>
                                          <h6><Link to={`/profile/${value.user.username}`}>{value.user.username}</Link></h6>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="image-block position-relative">
                                    <img style={{ height : 216 }} src={imageURL + '/' + value.image} className="img-fluid w-100 rounded" alt="product-img" />
                                    <h6 className="price"><span className="regular-price text-dark pr-2">{value.old_price}</span>{value.price}</h6>
                                 </div>
                                 <div className="product-description mt-3">
                                    <h6 className="mb-1">{value.title}</h6>
                                    <p className="mb-0">{value.description}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                            ))
                        }
                 
               </div>
         </div>                 
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>


                    </div>
                    </div>
                </div>
            </div>
        </div>
                    



    )
}

export default Main

import TinderCard from 'react-tinder-card'
import React , { useContext, useEffect , useRef, useState } from 'react'
import { ContextProvider } from '../context/Context'
import { Link, useHistory } from 'react-router-dom'
import API , { imageURL } from '../api/axios'

function MarketPlace() {

    const { authenticated , setAuthenticated } = useContext(ContextProvider)

    const [products , setProducts] = useState([])

    const refVideo = useRef(null)

    let history = useHistory()

    useEffect(() => {
      const randomProduct = async () => {
        await API.randomProduct().then((res) => {
            setProducts(res.data)
        })
      }
      randomProduct()
    } , [])

console.log(products)
    return (
        <div>
        <div className="header-for-bg">
        <div className="background-header position-relative">
           <img src="/images/page-img/profile-bg7.jpg" className="img-fluid rounded w-100 rounded rounded" alt="profile-bg" />
           <div className="title-on-header">
              <div className="data-block">
                 <h2>Marketplace</h2>
              </div>
           </div>
        </div>
     </div>
     <br></br>
     <br></br>
     <br></br>
     <div className="container">
               <div className="row">
     <div className="col-sm-12">

     <div className="row"> 



                        {
                            products.map((value) => (
                                <div className="col-sm-6 col-md-6 col-lg-4" key={value.id}>
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
                              <div className="iq-card-body">
                                 <div className="d-flex align-items-center justify-content-between pb-3">
                                    <div className="d-flex align-items-center">
                                       <img className="img-fluid rounded-circle avatar-30" src={ value.user.image ? imageURL + '/' + value.user.image : '/images/user/01.jpg'} alt="" />
                                       <div className="media-body ml-2">
                                          <p className="mb-0 line-height">Posted By</p>
                                          <h6><Link to={`/profile/${value.user.username}`}>{value.user.username}</Link></h6>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="image-block position-relative">
                                    <img style={{ height : 216 }} src={imageURL + '/' + value.image} className="img-fluid w-100 rounded" alt="product-img" />
                                    <h6 className="price"><span className="regular-price text-dark pr-2">{value.old_price}</span>{value.price}</h6>
                                 </div>
                                 <div className="product-description mt-3">
                                    <h6 className="mb-1">{value.title}</h6>
                                    <p className="mb-0">{value.description}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                            ))
                        }


                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
                    



    )
}

export default MarketPlace

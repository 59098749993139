import axios from 'axios'

export const API_URL = 'https://harmigo.nativecourses.com/api'

export const imageURL = 'https://harmigo.nativecourses.com/uploads'

// export const API_URL = 'http://127.0.0.1:3333/api'

// export const imageURL = 'http://127.0.0.1:3333/uploads'

export const instance = axios.create({
    baseURL: API_URL
  });

const APIS = {
    login : async (userData)  => {
         return await instance.post('/login' , userData)
    },
    register : async (userData)  => {
      return await instance.post('/register' , userData)
 },
 getUser : async ()  => {
  return await instance.get('/getUser' , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
 editProfile : async (data)  => {
  return await instance.put('/editProfile' , data , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
 profileData : async (username)  => {
  return await instance.get(`/profileData/${username}` , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
searchUser : async (username)  => {
  return await instance.get(`/searchUser/${username}` , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
myProfile : async ()  => {
  return await instance.get('/myProfile' , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
randomMedia : async ()  => {
  return await instance.get('/randomMedia' , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
randomProduct : async ()  => {
  return await instance.get('/randomProduct' , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
storeMedia : async (data)  => {
  return await instance.post('/media' , data , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
storeYoutube : async (data)  => {
  return await instance.post('/storeYoutube' , data , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
storeProduct : async (data)  => {
  return await instance.post('/product' , data , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
deleteMedia : async (id)  => {
  return await instance.delete(`/media/${id}` , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
deleteProduct : async (id)  => {
  return await instance.delete(`/product/${id}` , {
    headers : {
      'Authorization': `Bearer ${localStorage.getItem('token')}` 
    }
  })
},
}

export default APIS
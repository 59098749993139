import React , { useContext , useState } from 'react'
import { ContextProvider } from '../context/Context'
import API from '../api/axios'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'


function Register() {
    
    const {authenticated , setAuthenticated} = useContext(ContextProvider)

    const [userName , setUsername] = useState('')

    const [phone , setPhone] = useState('')

    const [email , setEmail] = useState('')

    const [password , setPassword] = useState('')

    const [passwordConfirmation , setPasswordConfirmation] = useState('')

    const [error , setError] = useState({})

    let history = useHistory()

    const registerAPI = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('username' , userName)
        formData.append('phone_number' , phone.replace(/\s/g,''))
        formData.append('email' , email)
        formData.append('password' , password)
        formData.append('password_confirmation' , passwordConfirmation)
        API.register(formData).then((res) => {
          console.log(res.data)
          setError({})
            localStorage.setItem("token" , res.data.token.token)
            setAuthenticated(true)
            history.push('/home')
        }).catch(err => {
          console.log(err.response.data)
            setError(err.response.data[0] || err.response.data)
        })
    }
    return (
        !authenticated ?
        <>
          <section className="sign-in-page">
            <div id="container-inside">
                <div id="circle-small"></div>
                <div id="circle-medium"></div>
                <div id="circle-large"></div>
                <div id="circle-xlarge"></div>
                <div id="circle-xxlarge"></div>
            </div>
              <div className="container p-0">
                  <div className="row no-gutters" style={{ display : 'flex' , justifyContent : 'center' , alignItems : 'center' , height : '100vh' }}>
                      <div className="col-md-6 text-center pt-5">
                          <div className="text-white">
                              {/* <a className="sign-in-logo mb-5" href="#"><img src="images/logo-full.png" className="img-fluid" alt="logo" /></a> */}
                              <Link className="sign-in-logo mb-5" to="/" style={{ color : 'white' , fontSize : 40 , fontWeight : 'bolder' }}>Harmigo</Link>
                              
                          </div>
                      </div>
                      <div className="col-md-6 bg-white pt-5" style={{ padding : 10 , borderRadius : 10 }}>
                          <div className="sign-in-from">
                              <h1 className="mb-0">Sign Up</h1>
                              <p>Sign up for free to be a part of our app.</p>
                              <form className="mt-4" onSubmit={registerAPI}>
                              <div className="form-group">
                                      <label for="exampleInputEmail1">Username</label>
                                      <input type="text" className="form-control mb-0" id="exampleInputEmail1" onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                                  </div>
                                  <div className="form-group">
                                      <label for="exampleInputEmail1">Email address</label>
                                      <input type="email" className="form-control mb-0" id="exampleInputEmail1" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                                  </div>
                                  <div className="form-group">
                                      <label for="exampleInputEmail1">Phone</label>
                                      <input type="text" className="form-control mb-0" id="exampleInputEmail1" onChange={(e) => setPhone(e.target.value)} placeholder="Phone" />
                                  </div>
                                  <div className="form-group">
                                      <label for="exampleInputPassword1">Password</label>
                                      <input type="password" className="form-control mb-0" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                  </div>
                                  <div className="form-group">
                                      <label for="exampleInputPassword1">Password Confirmation</label>
                                      <input type="password" className="form-control mb-0" id="exampleInputPassword1" onChange={(e) => setPasswordConfirmation(e.target.value)} placeholder="Password" />
                                  </div>
                                  <div className="d-inline-block w-100">
                                      <button type="submit" className="btn btn-primary float-right">Sign up</button>
                                  </div>
                                  {error.errors? <div className="col-12" style={{ backgroundColor : '#008ce9' , padding : 10 , color : 'white' , fontWeight : 'bold' , borderRadius : 5 }}>{error.errors[0].message}</div> : '' }
                                  <div className="sign-info">
                                      <span className="dark-color d-inline-block line-height-2">Already have an account? <Link to="/login">Sign in</Link></span>
                                      <ul className="iq-social-media">
                                          <li><a href="#"><i className="ri-facebook-box-line"></i></a></li>
                                          <li><a href="#"><i className="ri-twitter-line"></i></a></li>
                                          <li><a href="#"><i className="ri-instagram-line"></i></a></li>
                                      </ul>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          </>
                      :
                      window.location.href = "/"
    )
}

export default Register
import React , { useContext, useEffect , useRef, useState } from 'react'
import { ContextProvider } from '../context/Context'
import { useHistory , Link, Switch } from 'react-router-dom'
import API, { imageURL } from '../api/axios'
import { RouteWithSubRoutes } from '../utils/routes'

function Home({ routes , location }) {

    const { authenticated , setAuthenticated } = useContext(ContextProvider)

    const [visibility , setVisibility] = useState('hidden')

    const [users , setUsers] = useState([])

    const [error , setError] = useState('')

    const [imageName , setImageName] = useState('')

    useEffect(() => {
      if (authenticated && location.pathname == "/"){
        history.push('/home')
      }
    } , [])

    const [name , setName] = useState('')

    let history = useHistory()

    useEffect(() => {
      const checkUser = async () => {
        await API.getUser().then((res) => {
          setName(res.data.username)
          setImageName(res.data.image)
        })
      }
      checkUser()
    } , [])

    const searching = async (e) => {
       console.log(e.target.value)
       await API.searchUser(e.target.value).then(res => {
          setError('')
          setVisibility('')
          setUsers(res.data)
       }).catch(err => {
          if(e.target.value === '')
          setVisibility('hidden')
          else{
          setVisibility('')
          setError(err.response.data.message)
          }
       })
    }

  return (
    
      authenticated ?
    <div className="wrapper">
         <div className="iq-sidebar">
            <div id="sidebar-scrollbar">
               <nav className="iq-sidebar-menu">
                  <ul id="iq-sidebar-toggle" className="iq-menu">
                     <li>
                        <Link to="/home" className="iq-waves-effect"><i className="las la-newspaper"></i><span>Home Page</span></Link>
                     </li>
                     <li>
                        <Link to="/profile" className="iq-waves-effect"><i className="las la-user"></i><span>Profile</span></Link>
                     </li>     
                     <li>
                        <Link to="/marketplace" className="iq-waves-effect"><i className="las la-bullhorn"></i><span>Marketplace</span></Link>
                     </li>                      
                  </ul>
               </nav>
               <div className="p-3"></div>
            </div>
         </div>
         <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
               <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <div className="iq-navbar-logo d-flex justify-content-between">
                     <Link to="/home">
                     <img src="/images/harmigoLogo.png" className="img-fluid" alt="" />
                     <span>Harmigo</span>
                     </Link>
                     <div className="iq-menu-bt align-self-center">
                     <div className="wrapper-menu">
                        <div className="main-circle"><i className="ri-menu-line"></i></div>
                     </div>
                  </div>
                  </div>
                  <div className="iq-search-bar">
                     <form action="#" className="searchbox">
                        <input type="text" onChange={(e) => searching(e)} className="text search-input" placeholder="Type here to search..." />
                        <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                        <div className="text search-input" style={{ position : 'absolute' , height : 'auto' , visibility : visibility}}>
                           <div className="row">
                              {
                                 error ?
                                 <div className="col-lg-12">
                                    <div style={{ fontWeight : 'bold' , color : 'black' , backgroundColor : '#50b5ff' , borderRadius : 5 , margin : 5 , textAlign : 'center' }}>{error}</div>
                                 </div>
                                 :
                                 users.map((value) => (
                                    <div className="col-lg-12" key={value.id} onClick={e => window.location.href = `/profile/${value.username}`} style={{ cursor : 'pointer' , backgroundColor : '#50b5ff' , borderRadius : 5 , margin : 5 , padding : 3 }}>
                                    <div className="row">
                                 <div className="col-lg-3"><img style={{ width : '100%' , height : '85%' , borderRadius : '50%' }} src={value.image ? imageURL + '/' + value.image : '/images/user/11.png'} /></div>
                                 <div className="col-lg-9">
                                    <h5 style={{ marginTop : 3 , fontWeight : 'bold' , fontSize : 15 , color : 'black' }}>{value.username}</h5>
                                    <h6 style={{ fontSize : 13 , fontWeight : 'bold' , color : 'black' }}>{value.email}</h6>
                                 </div>
                                 </div>
                                 </div>
                                 ))
                              }
                           </div>
                        </div>
                     </form>

                  </div>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-label="Toggle navigation">
                  <i className="ri-menu-3-line"></i>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                     <ul className="navbar-nav ml-auto navbar-list">
                        <li>
                           <Link to="/profile" className="iq-waves-effect d-flex align-items-center">
                              <img src={ imageName ? imageURL + '/' + imageName : "/images/user/11.png"} className="img-fluid rounded-circle mr-3" alt="user" />
                              <div className="caption">
                                 <h6 className="mb-0 line-height">{name}</h6>
                              </div>
                           </Link>
                        </li>
                      
                     <li className="nav-item">
                           <Link to="#" className="search-toggle iq-waves-effect">
                              <div className="ri-notification-3-line"></div>
                           </Link>
                           <div className="iq-sub-dropdown">
                              <div className="iq-card shadow-none m-0">
                                 <div className="iq-card-body p-0 ">
                                    <div className="bg-primary p-3">
                                       <h5 className="mb-0 text-white">All Notifications<small className="badge  badge-light float-right pt-1">4</small></h5>
                                    </div>
                                    <Link to="#" className="iq-sub-card" >
                                       <div className="media align-items-center">
                                          <div className="">
                                             <img className="avatar-40 rounded" src="/images/user/11.png" alt="" />
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Emma Watson Bni</h6>
                                             <small className="float-right font-size-12">Just Now</small>
                                             <p className="mb-0">95 MB</p>
                                          </div>
                                       </div>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </li>
                        <li className="nav-item dropdown">
                           <Link to="#" className="search-toggle iq-waves-effect">
                              <div className="ri-chat-3-line"></div>
                           </Link>
                           <div className="iq-sub-dropdown">
                              <div className="iq-card shadow-none m-0">
                                 <div className="iq-card-body p-0 ">
                                    <div className="bg-primary p-3">
                                       <h5 className="mb-0 text-white">All Messages<small className="badge  badge-light float-right pt-1">5</small></h5>
                                    </div>
                                    <Link to="#" className="iq-sub-card" >
                                       <div className="media align-items-center">
                                          <div className="">
                                             <img className="avatar-40 rounded" src="/images/user/11.png" alt="" />
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Bni Emma Watson</h6>
                                             <small className="float-left font-size-12">13 Jun</small>
                                          </div>
                                       </div>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </li>
                     </ul>
                      <ul className="navbar-list">
                        <li>
                           <Link to="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                           <i className="ri-arrow-down-s-fill"></i>
                           </Link>
                           <div className="iq-sub-dropdown iq-user-dropdown">
                              <div className="iq-card shadow-none m-0">
                                 <div className="iq-card-body p-0 ">
                                    <div className="bg-primary p-3 line-height">
                                       <h5 className="mb-0 text-white line-height">Hello {name}</h5>
                                       <span className="text-white font-size-12">Available</span>
                                    </div>
                                    <Link to="/editProfile" className="iq-sub-card iq-bg-warning-hover">
                                       <div className="media align-items-center">
                                          <div className="rounded iq-card-icon iq-bg-warning">
                                             <i className="ri-profile-line"></i>
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Edit Profile</h6>
                                             <p className="mb-0 font-size-12">Modify your personal details.</p>
                                          </div>
                                       </div>
                                    </Link>
                                    {/* <Link to="account-setting.html" className="iq-sub-card iq-bg-info-hover">
                                       <div className="media align-items-center">
                                          <div className="rounded iq-card-icon iq-bg-info">
                                             <i className="ri-account-box-line"></i>
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Account settings</h6>
                                             <p className="mb-0 font-size-12">Manage your account parameters.</p>
                                          </div>
                                       </div>
                                    </Link> */}
                                    {/* <Link to="privacy-setting.html" className="iq-sub-card iq-bg-danger-hover">
                                       <div className="media align-items-center">
                                          <div className="rounded iq-card-icon iq-bg-danger">
                                             <i className="ri-lock-line"></i>
                                          </div>
                                          <div className="media-body ml-3">
                                             <h6 className="mb-0 ">Privacy Settings</h6>
                                             <p className="mb-0 font-size-12">Control your privacy parameters.</p>
                                          </div>
                                       </div>
                                    </Link> */}
                                    <div className="d-inline-block w-100 text-center p-3">
                                      <Link className="bg-primary iq-sign-btn" to="#" onClick={(e) => {
                                        localStorage.removeItem("token")
                                          history.push('/login')
                                       }} 
                                       role="button">
                                         Sign out
                                         <i className="ri-login-box-line ml-2"></i>
                                      </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </li>
                     </ul>

                  </div>
               </nav>
            </div>
         </div>
         <Switch>
                    {
                      routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                      ))
                    }
                </Switch>
         </div>
         :
         window.location.href = "/login"
  )

}

export default Home

import TinderCard from 'react-tinder-card'
import React , { useContext, useEffect , useRef, useState } from 'react'
import { ContextProvider } from '../context/Context'
import { useHistory } from 'react-router-dom'
import API, { imageURL } from '../api/axios'
import './Main.css'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

function EditProfile() {

    const { authenticated , setAuthenticated } = useContext(ContextProvider)

    const [name , setName] = useState('')

    const [contacts , setContacts] = useState({})

    const [user , setUser] = useState({})

    const [username , setUsername] = useState('')

    const [phoneNumber , setPhoneNumber] = useState('')

    const [image , setImage] = useState('')
      
    let history = useHistory()

    const [error , setError] = useState('')

    const [success , setSuccess] = useState('')

    useEffect(() => {
      const myProfile = async () => {
        await API.myProfile().then((res) => {
          setUser(res.data)
          setName(res.data.username)
          setContacts(res.data.contacts !== '' ? JSON.parse(res.data.contacts) : {})
        })
      }
      myProfile()
    } , [])

    const handleUpdate = () => {
        const formData = new FormData()
        formData.append('username' , username)
        formData.append('phone_number' , phoneNumber)
        formData.append('image' , image)
        Object.keys(contacts).length !== 0 ? formData.append('contacts' , JSON.stringify(contacts)) : <></>
        API.editProfile(formData).then(res => {
            setError('')
            setUsername('')
            setPhoneNumber('')
            setContacts('')
            setImage('')
            setSuccess(res.data.message)
        })
        .catch(err => {
            setSuccess('')
            if(err.response.data.errors)
            setError(err.response.data.errors[0].message)
            else
            setError(err.response.data.message)
        })
    }


    return (
        <div>
          <div className="container">
                <div className="row">
                <div className="col-sm-12">
                     <div className="iq-card" style={{ marginTop : 80 }}>
                        <div className="iq-card-body profile-page p-0">
                           <div className="profile-header">
                              <div className="cover-container">
                                 <img src="/images/page-img/profile-bg1.jpg" alt="profile-bg" className="rounded img-fluid" />
                                 {/* <ul className="header-nav d-flex flex-wrap justify-end p-0 m-0">
                                    <li><a href="#"><i className="ri-pencil-line"></i></a></li>
                                    <li><a href="#"><i className="ri-settings-4-line"></i></a></li>
                                 </ul> */}
                              </div>
                              <div className="user-detail text-center">
                                 <div className="profile-img">
                                 <img style={{ height : 130 }} src={ user.image ? imageURL + '/' + user.image : "/images/user/11.png"} alt="profile-img" className="avatar-130 img-fluid" />
                                 </div>
                                 <div className="profile-detail">
                                    <h3>{name}</h3>
                                 </div>
                              </div>
                              <div className="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
                                 <div className="social-links">
                                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                       <li className="text-center pr-3">
                                          <a href={contacts.facebook} target="_blank"><img src="/images/icon/08.png" className="img-fluid rounded" alt="facebook" /></a>
                                       </li>
                                       
                                       <li className="text-center pr-3">
                                          <a href={contacts.instagram} target="_blank"><img src="/images/icon/10.png" className="img-fluid rounded" alt="Instagram" /></a>
                                       </li>
                                      
                                       <li className="text-center pr-3">
                                          <a href={contacts.youtube} target="_blank"><img src="/images/icon/12.png" className="img-fluid rounded" alt="You tube" /></a>
                                       </li>
                                      
                                    </ul>
                                 </div>
                                 <div className="social-info">
                                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">

                                       <li className="text-center pl-3">
                                          <h6>Followers</h6>
                                          <p className="mb-0">206</p>
                                       </li>
                                       <li className="text-center pl-3">
                                          <h6>Following</h6>
                                          <p className="mb-0">100</p>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  
                  </div>
     
                </div>
            </div>
          <br></br>
          

        
     <div className="container">
               <div className="row">
     <div className="col-sm-12" style={{ marginBottom : 50 }}>
<Form.Group>
  <Form.Label>Username</Form.Label>
  <Form.Control size="lg" type="text" defaultValue={user.username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter username" />
  <br />
  <Form.Label>Email</Form.Label>
  <Form.Control size="lg" type="email" defaultValue={user.email} placeholder="Enter email" />
  <br />
  <Form.Label>Phone Number</Form.Label>
  <Form.Control size="lg" type="text" defaultValue={user.phone_number} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
  <br />
  <Form.Label>Facebook</Form.Label>
  <Form.Control size="lg" type="text" defaultValue={contacts.facebook ? contacts.facebook : ''} onChange={(e) => setContacts({...contacts , facebook : e.target.value})} placeholder="Facebook" />
  <br />
  <Form.Label>Youtube</Form.Label>
  <Form.Control size="lg" type="text" defaultValue={contacts.youtube ? contacts.youtube : ''} onChange={(e) => setContacts({...contacts , youtube : e.target.value})} placeholder="Youtube" />
  <br />
  <Form.Label>Instagram</Form.Label>
  <Form.Control size="lg" type="text" defaultValue={contacts.instagram ? contacts.instagram : ''} onChange={(e) => setContacts({...contacts , instagram : e.target.value})} placeholder="Instagram" />
  <br />
  <Form.File id="file" label="Image" onChange={(e) => setImage(e.target.files[0])} />
</Form.Group>
<div className="row">

    <div className="col-lg-2">
    <Button variant="primary" onClick={handleUpdate} style={{ width : 150 , height : 50 , fontWeight : 'bold' }}>
    Save
    </Button>
    </div>

    <div className="col-lg-10">
    {error ? <div className="col-lg-12 alert alert-danger" style={{ color : 'black' , fontWeight : 'bold' }}>{error}</div> : ''}
    {success ? <div className="col-lg-12 alert alert-danger" style={{ color : 'green' , fontWeight : 'bold' }}>{success}</div> : ''}
    </div>

</div>
                    </div>
                </div>
            </div>
        </div>
                    



    )
}

export default EditProfile

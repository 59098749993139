import {
    Route
  } from "react-router-dom";
import EditProfile from "../pages/EditProfile";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Main from "../pages/Main";
import MarketPlace from "../pages/MarketPlace";
import Profile from "../pages/Profile";
import Register from "../pages/Register";
import VariantProfile from "../pages/VariantProfile";

  export const routes = [
    
    {
      path: "/login",
      component: Login
    },
    {
      path: "/register",
      component: Register
    },
    {
      path: "/",
      component: Home,
      routes: [
        {
          path: "/home",
          component: Main
        },
        {
          path: "/profile",
          exact: true,
          component: Profile
        },
        {
          path: "/profile/:username",
          component: VariantProfile
        },
        {
          path: "/marketplace",
          component: MarketPlace
        },
        {
          path: "/editProfile",
          component: EditProfile
        }
      ]
    }
  ];

  export function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }
import TinderCard from 'react-tinder-card'
import React , { useContext, useEffect , useRef, useState } from 'react'
import { ContextProvider } from '../context/Context'
import { useHistory } from 'react-router-dom'
import API, { imageURL } from '../api/axios'
import './Main.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

function Profile() {

    const { authenticated , setAuthenticated } = useContext(ContextProvider)

    const [name , setName] = useState('')

    const [contacts , setContacts] = useState({})

    const [media , setMedia] = useState([])

    const [products , setProducts] = useState([])

    const [ imageName , setImageName ] = useState('')

    const refVideo = useRef(null)

    let history = useHistory()

    const [showPhoto, setShowPhoto] = useState(false);

    const handleClosePhoto = () => {
       setShowPhoto(false)
       setType('')
       setError('')
    }

    const handleShowPhoto = () => {
       setShowPhoto(true)
       setType('photo')
    }

    const [showVideo, setShowVideo] = useState(false);

    const handleCloseVideo = () => {
       setShowVideo(false)
       setType('')
       setError('')
    }

    const handleShowVideo = () => {
       setShowVideo(true)
       setType('video')
      }

    const [showYoutube, setShowYoutube] = useState(false);

    const handleCloseYoutube = () => {
       setShowYoutube(false)
       setType('')
       setError('')
    }

    const handleShowYoutube = () => {
       setShowYoutube(true)
       setType('youtube')
   }

   const [showProduct, setShowProduct] = useState(false);

    const handleCloseProduct = () => {
       setShowProduct(false)
       setError('')
    }

    const handleShowProduct = () => {
       setShowProduct(true)
   }

    const [title , setTitle] = useState('')
    const [description , setDescription] = useState('')
    const [file , setFile] = useState('')
    const [type , setType] = useState('')

    const [titleProduct , setTitleProduct] = useState('')
    const [descriptionProduct , setDescriptionProduct] = useState('')
    const [imageProduct , setImageProduct] = useState('')
    const [priceProduct , setPriceProduct] = useState(0)
    const [oldPriceProduct , setOldPriceProduct] = useState(0)

    const [error , setError] = useState('')

    const storeMedia = () => {
      const formData = new FormData()
        formData.append('title' , title)
        formData.append('description' , description)
        formData.append('file' , file)
        formData.append('type' , type)
        if(type === 'photo' || type === 'video'){
        API.storeMedia(formData).then(res => {
           window.location.reload()
        })
        .catch(err => setError(err.response.data.errors[0].message))
      }
      else{
         API.storeYoutube(formData).then(res => {
            window.location.reload()
         })
         .catch(err => setError(err.response.data.errors[0].message))
      }
    }

    const storeProduct = () => {
      const formData = new FormData()
        formData.append('title' , titleProduct)
        formData.append('description' , descriptionProduct)
        formData.append('image' , imageProduct)
        formData.append('old_price' , oldPriceProduct)
        formData.append('price' , priceProduct)
        API.storeProduct(formData).then(res => {
           window.location.reload()
        })
        .catch(err => setError(err.response.data.errors[0].message))
    }

    useEffect(() => {
      const myProfile = async () => {
        await API.myProfile().then((res) => {
            setMedia(res.data.media)
            console.log(res.data.media)
            setProducts(res.data.products)
          setName(res.data.username)
          setImageName(res.data.image)
          setContacts(res.data.contacts !== '' ? JSON.parse(res.data.contacts) : {})
        })
      }
      myProfile()
    } , [])

  const [lastDirection, setLastDirection] = useState()

  const swiped = (direction, nameToDelete) => {
    console.log('removing: ' + nameToDelete)
    setLastDirection(direction)
  }

  const outOfFrame = (name) => {
    console.log(name + ' left the screen!')
  }

  const youtube_parser = (url) => {
   var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
   var match = url.match(regExp);
   return (match&&match[7].length==11) ? match[7] : false;
}

const handleDeleteMedia = (id) => {
   API.deleteMedia(id).then(res => setMedia(media.filter(item => item.id !== id)))
   .catch(e => console.log(e.response.data))
}

const handleDeleteProduct = (id) => {
   API.deleteProduct(id).then(res => setProducts(products.filter(item => item.id !== id)))
   .catch(e => console.log(e.response.data))
}

    return (
        <div>
          <div className="container">
                <div className="row">
                <div className="col-sm-12">
                     <div className="iq-card" style={{ marginTop : 80 }}>
                        <div className="iq-card-body profile-page p-0">
                           <div className="profile-header">
                              <div className="cover-container">
                                 <img src="/images/page-img/profile-bg1.jpg" alt="profile-bg" className="rounded img-fluid" />
                                 {/* <ul className="header-nav d-flex flex-wrap justify-end p-0 m-0">
                                    <li><a href="#"><i className="ri-pencil-line"></i></a></li>
                                    <li><a href="#"><i className="ri-settings-4-line"></i></a></li>
                                 </ul> */}
                              </div>
                              <div className="user-detail text-center">
                                 <div className="profile-img">
                                    <img style={{ height : 130 }} src={ imageName ? imageURL + '/' + imageName : "/images/user/11.png"} alt="profile-img" className="avatar-130 img-fluid" />
                                 </div>
                                 <div className="profile-detail">
                                    <h3>{name}</h3>
                                 </div>
                              </div>
                              <div className="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
                                 <div className="social-links">
                                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                       <li className="text-center pr-3">
                                          <a href={contacts.facebook} target="_blank"><img src="/images/icon/08.png" className="img-fluid rounded" alt="facebook" /></a>
                                       </li>
                                       
                                       <li className="text-center pr-3">
                                          <a href={contacts.instagram} target="_blank"><img src="/images/icon/10.png" className="img-fluid rounded" alt="Instagram" /></a>
                                       </li>
                                      
                                       <li className="text-center pr-3">
                                          <a href={contacts.youtube} target="_blank"><img src="/images/icon/12.png" className="img-fluid rounded" alt="You tube" /></a>
                                       </li>
                                      
                                    </ul>
                                 </div>
                                 <div className="social-info">
                                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">

                                       <li className="text-center pl-3">
                                          <h6>Followers</h6>
                                          <p className="mb-0">206</p>
                                       </li>
                                       <li className="text-center pl-3">
                                          <h6>Following</h6>
                                          <p className="mb-0">100</p>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  
                  </div>
     
                </div>
            </div>
          <br></br>
          

          <div className="container">
               <div className="row" style={{ backgroundColor : '#50b5ff' , margin : 20 , padding : 30 , borderRadius : 20 }}>
                  <div className="col-lg-3">
                     <button className="btn btn-info" onClick={handleShowPhoto}
                     style={{ fontWeight : 'bold' , color : 'black' , width : 200 , margin : 10 , height : 50 , fontSize : 18 }}>Add Photo</button>
                     <Modal
        show={showPhoto}
        onHide={handleClosePhoto}
        backdrop="static"
        keyboard={false}
        style={{ marginTop : 70 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {error ? <div className="col-lg-12 alert alert-danger" style={{ color : 'black' , fontWeight : 'bold' }}>{error}</div> : ''}
           <Form.Group>
           <Form.File id="file" label="Photo" onChange={(e) => setFile(e.target.files[0])} />
           <br />
           <Form.Label>Title</Form.Label>
           <Form.Control size="lg" type="text" onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
                  <br />
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
           </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePhoto}>
            Close
          </Button>
          <Button variant="primary" onClick={storeMedia}>Save</Button>
        </Modal.Footer>
      </Modal>
                  </div>
                  <div className="col-lg-3">
                     <button className="btn btn-secondary" onClick={handleShowVideo}
                     style={{ fontWeight : 'bold' , color : 'black' , width : 200 , margin : 10 , height : 50 , fontSize : 18 }}>Add Video</button>
                  <Modal
        show={showVideo}
        onHide={handleCloseVideo}
        backdrop="static"
        keyboard={false}
        style={{ marginTop : 70 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {error ? <div className="col-lg-12 alert alert-danger" style={{ color : 'black' , fontWeight : 'bold' }}>{error}</div> : ''}
           <Form.Group>
           <Form.File id="file" onChange={(e) => {

              var videoMaxTime = "01:00"; //minutes:seconds   //video
              function secondsToTime(in_seconds) {

               var time = '';
               in_seconds = parseFloat(in_seconds.toFixed(2));
             
               var hours = Math.floor(in_seconds / 3600);
               var minutes = Math.floor((in_seconds - (hours * 3600)) / 60);
               var seconds = in_seconds - (hours * 3600) - (minutes * 60);
               //seconds = Math.floor( seconds );
               seconds = seconds.toFixed(0);
             
               if (hours < 10) {
                 hours = "0" + hours;
               }
               if (minutes < 10) {
                 minutes = "0" + minutes;
               }
               if (seconds < 10) {
                 seconds = "0" + seconds;
               }
               var time = minutes + ':' + seconds;
             
               return time;
             
             }

              let file = e.target.files[0]
              let reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = (e) => {
               if (file.type == "video/mp4") {
                  var videoElement = document.createElement('video');
                  videoElement.src = e.target.result;
                  var timer = setInterval(function() {
                    if (videoElement.readyState === 4) {
                      var timeCheck = secondsToTime(videoElement.duration);
                      if (timeCheck > videoMaxTime) {
                         console.log(timeCheck)
                        setError('1 minutes video only')
                        setFile('')
                      }
                      else
                      setFile(file)
                      clearInterval(timer);
                    }
                  }, 500)
                }
               
              }
           }} label="Video" />
           <br />
           <Form.Label>Title</Form.Label>
           <Form.Control size="lg" type="text" onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
                  <br />
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
           </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVideo}>
            Close
          </Button>
          <Button variant="primary" onClick={storeMedia}>Save</Button>
        </Modal.Footer>
      </Modal>
                  </div>
                  <div className="col-lg-3">
                     <button className="btn btn-danger" onClick={handleShowYoutube}
                     style={{ fontWeight : 'bold' , color : 'black' , width : 200 , margin : 10 , height : 50 , fontSize : 18 }}>Add Youtube</button>
                  <Modal
        show={showYoutube}
        onHide={handleCloseYoutube}
        backdrop="static"
        keyboard={false}
        style={{ marginTop : 70 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Youtube</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {error ? <div className="col-lg-12 alert alert-danger" style={{ color : 'black' , fontWeight : 'bold' }}>{error}</div> : ''}
           <Form.Group>
           <Form.Label>Youtube Link</Form.Label>
           <Form.Control size="lg" type="text" placeholder="Youtube Link" onChange={(e) => {
              const checkYoutubeLink = youtube_parser(e.target.value)
            checkYoutubeLink ? setFile(`https://www.youtube.com/embed/${checkYoutubeLink}`) : setFile('')
              }} />
           <br />
           <Form.Label>Title</Form.Label>
           <Form.Control size="lg" type="text" placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                  <br />
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
           </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseYoutube}>
            Close
          </Button>
          <Button variant="primary" onClick={storeMedia}>Save</Button>
        </Modal.Footer>
      </Modal>
                  </div>
                  <div className="col-lg-3">
                     <button className="btn btn-warning" onClick={handleShowProduct}
                     style={{ fontWeight : 'bold' , color : 'black' , width : 200 , margin : 10 , height : 50 , fontSize : 18 }}>Add Product</button>
                  <Modal
        show={showProduct}
        onHide={handleCloseProduct}
        backdrop="static"
        keyboard={false}
        style={{ marginTop : 70 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {error ? <div className="col-lg-12 alert alert-danger" style={{ color : 'black' , fontWeight : 'bold' }}>{error}</div> : ''}
           <Form.Group>
           <Form.File id="file" label="Photo" onChange={(e) => setImageProduct(e.target.files[0])} />
           <Form.Label>Title</Form.Label>
           <Form.Control size="lg" type="text" onChange={(e) => setTitleProduct(e.target.value)} placeholder="Title" />
                  <div className="row">
                     <div className="col-lg-6">
                     <Form.Label>Old Price</Form.Label>
           <Form.Control size="lg" type="text" onChange={(e) => setOldPriceProduct(e.target.value)} defaultValue="0" placeholder="Old Price" />
                     </div>
                     <div className="col-lg-6">
                     <Form.Label>Price</Form.Label>
           <Form.Control size="lg" type="text" onChange={(e) => setPriceProduct(e.target.value)} defaultValue="0" placeholder="Price" />
                     </div>
                  </div>
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={(e) => setDescriptionProduct(e.target.value)} placeholder="Description" />
           </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseProduct}>
            Close
          </Button>
          <Button variant="primary" onClick={storeProduct}>Save</Button>
        </Modal.Footer>
      </Modal>
                  </div>
               </div>
        </div>


     <div className="container">
               <div className="row">
     <div className="col-sm-12">
     <div className="iq-card">
                        <div className="iq-card-body p-0">
                           <div className="user-tabing">
                              <ul className="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link active" data-toggle="pill" href="#photos">Photos</a>
                                 </li>
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link" data-toggle="pill" href="#videos">Videos</a>
                                 </li>
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link" data-toggle="pill" href="#youtube">Youtube</a>
                                 </li>
                                 <li className="col-sm-3 p-0">
                                    <a className="nav-link" data-toggle="pill" href="#products">Marketplace</a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>





                     <div className="tab-content">
                     <div className="tab-pane fade active show" id="photos" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row" style={{ padding : 20 }}>
                                  
                                 <div className="col-md-12">
                                       <div className="tab-content">
              
                                       <div id="content-page" className="content-page">
               <div className="row">
                  
               {
                            media.map((value) => {
                              if(value.type === "photo")
                              return (
                                <div className="col-sm-6 col-md-6 col-lg-4" key={value.id}>
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
                              <div className="iq-card-body">
                                 <div className="image-block position-relative">
                                    <img style={{ height : 216 }} src={imageURL + '/' + value.file} className="img-fluid w-100 rounded" alt="product-img" />
                                    <h6 className="price"><span className="regular-price text-dark pr-2">{value.old_price}</span>{value.price}</h6>
                                 </div>
                                 <div className="product-description mt-3">
                                    <h6 className="mb-1">{value.title}</h6>
                                    <p className="mb-0">{value.description}</p>
                                    <div style={{ float : 'right' }}>
                                    <a onClick={(e) => handleDeleteMedia(value.id)} style={{ margin : 5 , fontSize : 22 , color : 'red' , cursor : 'pointer' }}><i className="ri-delete-bin-line"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                            )})
                        }
                 
               </div>
         </div>                 
                        </div>
                    </div>
                                    {lastDirection ? <h2 className='infoText' style={{ color : 'black' }}>You swiped {lastDirection}</h2> : <h2 className='infoText' />}

                                 </div>
                              </div>
                           </div>
                        </div>
         
                        <div className="tab-pane fade" id="videos" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row" style={{ padding : 20 }}>


                                 <div className="col-md-12">
                                       <div className="tab-content">
              
                                       <div id="content-page" className="content-page">
               <div className="row">
                  
               {
                            media.map((value) => {
                              if(value.type === "video")
                              return (
                                <div className="col-lg-6" key={value.id}>
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
                              <div className="iq-card-body">
                                 <div className="image-block position-relative">
                                 <video style={{ width : '100%' }} ref={refVideo}
                  //   onMouseOver={(e) => e.target.play()}
                  // poster={`/images/user/11.png`}
                    onClick={(e) => { e.target.paused ? e.target.play() : e.target.pause() }}
                     onDoubleClick={(e) => { e.target.muted ? e.target.muted = false : e.target.muted = true }}
                      muted={true}>
                    <source src={`${imageURL}/${value.file}`}   
            typeof="video/webm" />
                    <source src={`${imageURL}/${value.file}`}
            typeof="video/mp4" />
                    </video>                                    <h6 className="price"><span className="regular-price text-dark pr-2">{value.old_price}</span>{value.price}</h6>
                                 </div>
                                 <div className="product-description mt-3">
                                    <h6 className="mb-1">{value.title}</h6>
                                    <p className="mb-0">{value.description}</p>
                                    <div style={{ float : 'right' }}>
                                    <a onClick={(e) => handleDeleteMedia(value.id)} style={{ margin : 5 , fontSize : 22 , color : 'red' , cursor : 'pointer' }}><i className="ri-delete-bin-line"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                            )})
                        }
                 
               </div>
               <h3 className='infoText' style={{ color : 'black' }}>Click to play or pause and double click to mute or unmute</h3>

         </div>         
                 
                        </div>
                    </div>
                                  
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="tab-pane fade" id="youtube" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row">


                                 <div className="col-md-12">
                                       <div className="tab-content">
              
                                       <div id="content-page" className="content-page">
               <div className="row">
                  
               {
                            media.map((value) => {
                              if(value.type === "youtube")
                              return (
                                <div className="col-lg-6" key={value.id}>
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
                              <div className="iq-card-body">
                                 <div className="image-block position-relative">
                                 <iframe className="cardContainerYoutube" src={value.file} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <h6 className="price"><span className="regular-price text-dark pr-2">{value.old_price}</span>{value.price}</h6>
                                 </div>
                                 <div className="product-description mt-3">
                                    <h6 className="mb-1">{value.title}</h6>
                                    <p className="mb-0">{value.description}</p>
                                    <div style={{ float : 'right' }}>
                                    <a onClick={(e) => handleDeleteMedia(value.id)} style={{ margin : 5 , fontSize : 22 , color : 'red' , cursor : 'pointer' }}><i className="ri-delete-bin-line"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                            )})
                        }
                 
               </div>
               <h3 className='infoText' style={{ color : 'black' }}>Click to play or pause and double click to mute or unmute</h3>

         </div>         
                 
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>

        <div className="tab-pane fade" id="products" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row">
                                  
                                    <div className="col-md-12">
                                       <div className="tab-content">
              
                                       <div id="content-page" className="content-page">
               <div className="row">
                  
               {
                            products.map((value) => (
                                <div className="col-sm-6 col-md-6 col-lg-4" key={value.id}>
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
                              <div className="iq-card-body">
                                 <div className="image-block position-relative">
                                    <img style={{ height : 216 }} src={imageURL + '/' + value.image} className="img-fluid w-100 rounded" alt="product-img" />
                                    <h6 className="price"><span className="regular-price text-dark pr-2">{value.old_price}</span>{value.price}</h6>
                                 </div>
                                 <div className="product-description mt-3">
                                    <h6 className="mb-1">{value.title}</h6>
                                    <p className="mb-0">{value.description}</p>
                                    <div style={{ float : 'right' }}>
                                    <a onClick={(e) => handleDeleteProduct(value.id)} style={{ margin : 5 , fontSize : 22 , color : 'red' , cursor : 'pointer' }}><i className="ri-delete-bin-line"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                            ))
                        }
                 
               </div>
         </div>                 
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

                    </div>
                    </div>
                </div>
            </div>
        </div>
                    



    )
}

export default Profile
